<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Quote Reference:  {{ quote.policy_number }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <b-tabs card>

            <b-tab v-for="(tab, index) in quote.data" :key="tab.name" :title="tab.title" :class="{ 'active': index === 0 }" class="mt-2">

              <div v-if="tab.data.length === 1" >
                <b-table :items="tab.data"></b-table>
              </div>

              <div v-else>

                <div v-if="tab.type == 'html'" v-html="tab.data">

                  {{ tab.data }}

                </div>

                <div v-else>

                  <table class="table">
                    <tr v-for="(item, key, subindex) in tab.data" :key="subindex">
                      <th>
                        {{ key | capitalize }}
                      </th>
                      <td v-html="item">
                        {{ item }}
                      </td>
                    </tr>
                  </table>

                </div>

              </div>

            </b-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="pull-right">Back</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
  import axios from '../../../shared/axios/auth'

  export default {
    name: 'Quote',
    props: {
      caption: {
        type: String,
        default: 'Quote Reference '
      },
    },
    data: () => {
      return {
        quote: null
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getQuote() {
          let url = null;
          if (localStorage.getItem('selected_product')) {
            url = '/quotes/' + this.$route.params.id + '/?product=' + localStorage.getItem('selected_product');
          } else {
            url = '/quotes/' + this.$route.params.id + '/';
          }

        axios.get(url).then(
          response => (
            this.quote = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.quote
      }
    },
    mounted() {
      this.getQuote()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
